export default {
    state: {
        auth: false,
        loading: true,
        error: null,
        system_settings: {},
        system_messages: {},
        app_settings: {},
        header: {
            fullHidden: true,
            show: false,
            step: 1,
            progress: '0%'
        },
        utm: '',
        noNeedCalendarUpdate: true,
        calendarLoading: false,
        timeSlotsLoading: false
    },
    mutations: {
        setAuth (state, payload) {
            state.auth = true;
            state.system_settings = payload.system_settings;
            state.system_messages = payload.system_messages;
            state.app_settings = payload.app_settings;
        },
        setLoading (state, payload) {
            state.loading = payload;
        },
        setError (state, payload) {
            state.error = payload;
        },
        setHeader (state, payload) {
            state.header = payload;
        },
        setUTM (state, payload) {
            state.utm = payload;
        },
        setNoNeedCalendarUpdate (state, payload) {
            state.noNeedCalendarUpdate = payload;
        },
        setCalendarLoading (state, payload) {
            state.calendarLoading = payload;
        },
        setTimeSlotsLoading (state, payload) {
            state.timeSlotsLoading = payload;
        },
    },
    actions: {
        setAuth ({commit}, payload) {
            commit('setAuth', payload);
        },
        setLoading ({commit}, payload) {
            commit('setLoading', payload);
        },
        setError ({commit}, payload) {
            commit('setError', payload);
        },
        setHeader ({commit}, payload) {
            commit('setHeader', payload);
        },
        setUTM ({commit}, payload) {
            commit('setUTM', payload);
        },
        setNoNeedCalendarUpdate ({commit}, payload) {
            commit('setNoNeedCalendarUpdate', payload);
        },
        setCalendarLoading ({commit}, payload) {
            commit('setCalendarLoading', payload);
        },
        setTimeSlotsLoading ({commit}, payload) {
            commit('setTimeSlotsLoading', payload);
        }
    },
    getters: {
        getAuth (state) {
            return state.auth;
        },
        getLoadingStatus (state) {
            return state.loading;
        },
        getError (state) {
            return state.error;
        },
        getMessages (state) {
            return state.system_messages;
        },
        getSettings (state) {
            return state.system_settings;
        },
        getAppSettings (state) {
            return state.app_settings;
        },
        getHeader (state) {
            return state.header;
        },
        getUTM (state) {
            return state.utm;
        },
        getNoNeedCalendarUpdate (state) {
            return state.noNeedCalendarUpdate;
        },
        getCalendarLoading (state) {
            return state.calendarLoading;
        },
        getTimeSlotsLoading (state) {
            return state.timeSlotsLoading;
        }
    }
}
